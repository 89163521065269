<template>
  <div
    class="packages-offer col col-desktop-3 col-desktop-s-4 col-tablet-4 col-mobile-4"
    :class="{ vod: isVodPackage, additional: isAdditionalPackage }"
  >
    <BadgeSequoia v-if="badge" :text="badge" type="brand" />

    <div class="inner">
      <div class="name" v-html="title" />

      <div v-if="isBasePackage" class="mb-16">
        <div class="flex mb-8">
          <IconSVG :svg="IconTV" class="mr-8" />
          <div>
            <div class="value" v-text="pack.channelsCount" />
            <div class="description" v-html="getTranslation('channels_count')" />
          </div>
        </div>

        <div class="flex">
          <IconSVG :svg="IconArchive" class="mr-8" />
          <div>
            <div class="value" v-html="getTranslation('best_on_tv')" />
            <div class="description" v-html="getTranslation('live_recordings')" />
          </div>
        </div>

        <div v-if="vodSourcesList" class="vod-source-list mt-8">
          <IconSVG :svg="IconVOD" class="mr-8" />
          <div>
            <div class="value" v-text="vodSourcesList" />
            <div class="description" v-html="getTranslation('online_cinemas')" />
          </div>
        </div>
      </div>

      <div v-else-if="isAdditionalPackage && channelsList" class="mb-16">
        <div class="description" v-html="getTranslation('channels_in_package')" />
        <TextWithTruncation
          class="value"
          :source-text="channelsList"
          number-of-lines="5"
          :show-button="false"
        />
      </div>

      <div class="mb-16 margin-top-auto">
        <span class="price-amount" v-text="pack.price" />&thinsp;
        <span class="price-period" v-text="priceUnit" />
      </div>

      <ButtonDefault type="button" view="tertiary" class="details" @click="onClick(pack.slug)">
        <span v-html="getTranslation('details')" />
      </ButtonDefault>
    </div>
  </div>
</template>

<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconTV from 'src/svg/tv.svg';
import IconArchive from 'src/svg/archive.svg';
import IconVOD from 'src/svg/vod.svg';
import { TPackage } from 'src/api/cms/types';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import TextWithTruncation from 'src/components/ui/TextWithTruncation.vue';
import { ADULT_OFFER_ID, ADULT_CHANNELS } from 'src/constants';

@Component({
  components: {
    BadgeSequoia,
    ButtonDefault,
    IconSVG,
    IconArchive,
    IconVOD,
    TextWithTruncation,
  },
})
export default class TrialSubscriptionOffer extends SequoiaComponent {
  IconTV = IconTV;
  IconArchive = IconArchive;
  IconVOD = IconVOD;

  @Prop({ required: true })
  pack!: TPackage;

  get isVodPackage() {
    return this.pack.type === 'vod';
  }

  get isAdditionalPackage() {
    return this.pack.type === 'additional';
  }

  get isBasePackage() {
    return this.pack.type === 'base';
  }

  get badge() {
    return this.getByLanguage(this.pack.badge)?.trim();
  }

  get title() {
    return this.getByLanguage(this.pack.title);
  }

  get vodSourcesList() {
    if (!this.pack.vod) {
      return;
    }

    return this.pack.vod
      .replace(/\s/g, '')
      .split(',')
      .map((source: string) => this.getTranslation(source))
      .join(', ');
  }

  getChannelById(id: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, id);
  }

  get shouldUseTvApiV2() {
    return this.$store.siteConfig?.tv?.shouldUseTvApiV2;
  }

  get channelsList() {
    if (!this.pack.channels.length) {
      return '';
    }

    if (this.pack.id === ADULT_OFFER_ID) {
      return this.pack.channels
        .map((channel) => ADULT_CHANNELS[channel.channelId]?.title)
        .join(', ');
    }

    return this.pack.channels
      .map(
        (channel) =>
          this.getChannelById(channel.channelId)?.[this.shouldUseTvApiV2 ? 'title' : 'name']
      )
      .filter((name) => name)
      .join(', ');
  }

  get priceUnit() {
    return this.getByLanguage(this.pack.priceUnit);
  }

  async mounted() {
    await actions.tvChannels.loadTvData(this.$store);
  }

  onClick(slug: string) {
    this.$router.push(`/packages/${slug}`);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

$row-margin-bottom: 8px; // space between rows
$last-row-margin-bottom: 25px; // bottom margin from the last row to the bottom border

.packages-offer {
  @extend %body2;

  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  color: var(--c-light-font-primary);

  .inner {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    border: 2px solid var(--alpha-light-5);
    border-radius: 12px;

    @include mobile-and-tablet {
      padding: 14px;
    }
  }

  .badge {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: var(--z-1);
    transform: translateY(-100%);
  }

  .name {
    @include heading5;

    margin-bottom: 16px;
  }

  .vod-source-list {
    display: flex;
    flex-wrap: nowrap;
  }

  .value {
    @include body1;
  }

  .description {
    color: var(--c-light-font-tertiary);
  }

  .price-amount {
    @include heading3;
  }

  .price-period {
    @extend %body2;
  }

  .vod {
    margin-top: $last-row-margin-bottom;
    margin-bottom: $last-row-margin-bottom;

    @include mobile-and-tablet {
      margin-bottom: $row-margin-bottom;
    }
  }

  .details {
    width: 100%;
  }
}
</style>
