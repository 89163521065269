<template>
  <CmsLayout class="packages-page" :class="theme">
    <main class="container-1440">
      <LoaderSpinner v-if="isLoading" />

      <template v-else>
        <BreadCrumbs class="with-side-padding" :with-current-level="false" :crumbs="crumbs" />

        <h2 class="with-side-padding mb-32" v-html="getTranslation('packages_title')" />

        <div v-if="basePackages.length">
          <h3 class="with-side-padding" v-html="getTranslation('base_packages')" />
          <ContentSlider type="adaptive" :theme-forced="theme" class="slider">
            <PackagesOffer v-for="(pack, i) in basePackages" :key="pack.id + i" :pack="pack" />
          </ContentSlider>
        </div>

        <div v-if="additionalPackages.length">
          <h3 class="with-side-padding" v-html="getTranslation('additional_packages')" />
          <ContentSlider type="adaptive" :theme-forced="theme" class="slider">
            <PackagesOffer
              v-for="(pack, i) in additionalPackages"
              :key="pack.id + i + 1"
              :pack="pack"
            />
          </ContentSlider>
        </div>

        <div v-if="vodPackages.length">
          <h3 class="with-side-padding" v-html="getTranslation('vod_packages')" />
          <ContentSlider type="adaptive" :theme-forced="theme" class="slider">
            <PackagesOffer v-for="(pack, i) in vodPackages" :key="pack.id + i + 2" :pack="pack" />
          </ContentSlider>
        </div>
      </template>
    </main>
  </CmsLayout>
</template>

<script lang="ts">
import { SequoiaPage } from 'src/mixins';
import logger from 'src/utils/logger';
import Component from 'vue-class-component';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import BreadCrumbs from 'src/components/ui/BreadCrumbs.vue';
import { actions } from 'src/store/actions';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import * as api from 'src/api';
import PackagesOffer from 'src/components/PackagesOffer.vue';
import { TPackage } from 'src/api/cms/types';
import { selectors } from 'src/store/selectors';

const log = logger('packages-page');

@Component({
  components: {
    LoaderSpinner,
    CmsLayout,
    BreadCrumbs,
    ContentSlider,
    PackagesOffer,
  },
})
export default class PackagesPage extends SequoiaPage {
  packages: Array<TPackage> = [];
  crumbs = [{ name: this.getTranslation('menu_homescreen'), link: '/' }];
  isLoading = true;

  get basePackages() {
    return this.packages.filter((pack) => pack.type === 'base');
  }

  get additionalPackages() {
    return this.packages.filter((pack) => pack.type === 'additional');
  }

  get vodPackages() {
    return this.packages.filter((pack) => pack.type === 'vod');
  }

  get packagesItems() {
    return selectors.packages.packagesSelector(this.$store).items || [];
  }

  async mounted() {
    this.packages = await Promise.all(
      this.packagesItems
        .filter((item) => item.show)
        .map(async (item) => {
          const getOfferChannelsResponse = await api.offers
            .getOfferChannels(item.id)
            .catch((err) => {
              log.error('getOfferChannels:', err);
            });
          const channels = getOfferChannelsResponse?.channels;
          const offer: any = await api.offers.getOffer(item.id).catch((err) => {
            log.error('getOffer:', err);
          });

          return {
            ...item,
            price: offer?.preview?.price || '0',
            channelsCount: channels?.length || 0,
            channels: channels || [],
          };
        })
    );
    this.isLoading = false;

    actions.common.showFooter(this.$store);
  }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.packages-page {
  padding-top: 64px;

  @include tablet {
    margin-top: 48px;
  }

  @include mobile {
    margin-top: 32px;
  }

  .slider {
    margin-top: 24px;

    @include mobile-and-tablet {
      margin-top: 16px;
    }
  }

  &::v-deep {
    .content-slider {
      margin-bottom: 48px;

      @include tablet {
        margin-bottom: 32px;
      }

      @include mobile {
        margin-bottom: 24px;
      }

      .slider-items {
        margin: 0;
      }
    }
  }
}
</style>
